<script setup lang="ts">
const props = defineProps<{
  data?: any
}>()

const { t } = useI18n()
const localePath = useLocalePath()

const hostPort = await useGetHost('hostport')
const host = _get(hostPort, 0)
const port = _get(hostPort, 1)
</script>

<template>
  <div v-if="props?.data" class="mx-auto py-6">
    <ULink
      :to="port ? `http://${host}:${port}${localePath(`/order/${props?.data}`)}` : `http://${host}${localePath(`/order/${props?.data}`)}`"
      target="_blank"
      active-class="text-blue"
      inactive-class="text-blue-500 hover:text-blue-700"
      class="block md:inline py-2 px-6 text-left text-xl"
    >
      {{ t('order_detail') }}
    </ULink>

    <ULink
      :to="port ? `http://${host}:${port}${localePath(`/order-qrcode/${props?.data}`)}` : `http://${host}${localePath(`/order-qrcode/${props?.data}`)}`"
      target="_blank"
      active-class="text-blue"
      inactive-class="text-blue-500 hover:text-blue-700"
      class="block md:inline py-2 px-6 text-left text-xl"
    >
      {{ t('order_qrcode') }}
    </ULink>
  </div>
</template>
